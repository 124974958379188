import React, { useEffect } from "react";
import "./App.css";

import {
  Card,
  WixDesignSystemProvider,
  Button,
  MarketingLayout,
  SkeletonRectangle,
  SkeletonGroup,
  Box,
  Transition,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import * as Icons from "@wix/wix-ui-icons-common";
import { ReactTagManager } from "react-gtm-ts";

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const appId = new URLSearchParams(window.location.search).get("appId");
  const [client, setClient] = React.useState(null as any);
  const [message, setMessage] = React.useState({} as any);
  const [showFade, setShowFade] = React.useState(false);

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
    fetch(
      `https://certifiedcode.wixsite.com/custom-dashboard/_functions/appmessaging/${appId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage(data);
        setShowFade(true);
      });
    // // Check if the iframe has already been loaded on this page session
    // if (sessionStorage.getItem("iframeLoaded")) {
    //   // Hide the current iframe by setting display to none
    //   document.body.style.display = "none"; // Hide the entire body of the iframe content
    // } else {
    //   // Mark the iframe as loaded
    //   sessionStorage.setItem("iframeLoaded", "true");
    // }
    // // clean when unload
    // window.addEventListener("beforeunload", () => {
    //   sessionStorage.removeItem("iframeLoaded");
    // });
  }, []);

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      {message?._id ? (
        <Transition
          show={showFade}
          enterAnimation={{
            fadeIn: {
              duration: "slow02",
              easing: "enterEasing",
            },
          }}
        >
          <Card>
            <MarketingLayout
              size="tiny"
              title={message.title}
              description={message.description}
              actions={
                <Button
                  size="small"
                  skin="ai"
                  suffixIcon={<Icons.AIFilledSmall />}
                  onClick={() => {
                    client.dashboard.navigate({ pageId: message.buttonLink });
                  }}
                  target={"_blank"}
                >
                  {message.buttonCta}
                </Button>
              }
            />
          </Card>
        </Transition>
      ) : (
        <Card>
          <MarketingLayout
            size="tiny"
            title={
              <SkeletonGroup skin="light">
                <SkeletonRectangle height={"24px"} width={"565px"} />
              </SkeletonGroup>
            }
            description={
              <SkeletonGroup skin="light">
                <Box gap="SP2" direction="vertical">
                  <SkeletonRectangle height={"18px"} width={"565px"} />
                  {/* <SkeletonRectangle height={"18px"} width={"465px"} /> */}
                </Box>
              </SkeletonGroup>
            }
            actions={
              <SkeletonGroup skin="light">
                <SkeletonRectangle height={"30px"} width={"128px"} />
              </SkeletonGroup>
            }
          />
        </Card>
      )}
      {/* </SidePanel> */}
    </WixDesignSystemProvider>
  );
}

export default App;
